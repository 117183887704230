<template>
  <div @click="handleClick()">
    <component :is="linkToProfile ? 'router-link' : 'div'" :to="compProfileUrl" class="block">
      <UtilTileBase
        :bordered="bordered"
        :autoWidth="autoWidth"
        :borderClass="bordered ? 'border border-gray' : undefined"
        class="overflow-hidden"
        centered
        :vertical="vertical"
      >
        <template v-if="showAvatar" #icon>
          <UtilAvatar
            :extraLarge="extraLarge"
            :large="!compact || vertical"
            class="text-white"
            :data-initial="compInitials"
          >
            <template v-if="(locked || !appIs.auth) && !userAlwaysUnlocked">
              <img v-lazy="require('@/assets/images/user/avatar-locked.png')" />
            </template>

            <template v-else>
              <template v-if="lazyLoad">
                <img v-if="compAvatarUrl" v-lazy="compAvatarUrl" alt="..." />
              </template>

              <template v-else>
                <img v-if="compAvatarUrl" :src="compAvatarUrl" alt="..." />
              </template>

              <span
                v-if="
                  showVerifiedBadge &&
                  ((showPendingVerification && pendingVerification) ||
                    user.verified === 1 ||
                    user.verified === true)
                "
                class="avatar-icon verified-icon tooltip cursor-help"
                :data-tooltip="compVerifiedToolTip"
              >
                <UtilVerifiedBadge :showPendingVerification="showPendingVerification && pendingVerification" />
              </span>

              <!-- membership status' -->
              <UtilMembershipStatus
                v-if="showMembershipStatus"
                :memberStatus="compMembershipStatus"
                class="member-status"
              />
            </template>
          </UtilAvatar>
        </template>

        <template #body>
          <slot name="body">
            <div
              v-if="showUser || showCompany"
              class="overflow-hidden uac-body"
              :class="{
                'mt-1': vertical && !compact,
                'ml-2': !compact && showAvatar,
                'ml-1': compact && showAvatar
              }"
            >
              <p v-if="showUser" class="leading-tight capitalize truncate tile-title">
                <strong v-if="(locked || !appIs.auth) && !userAlwaysUnlocked">Members access</strong>
                <strong v-else>{{ compUserName }}</strong>
              </p>

              <p
                v-if="showCompany && compCompanyName"
                class="flex items-center leading-tight tile-subtitle"
                :class="{ 'mt-1': !compact, 'justify-center': vertical }"
              >
                <img
                  v-if="showFlag"
                  src="@/assets/images/flags/rounded/gb.svg"
                  class="h-5 mr-1 leading-none"
                  alt="GB"
                />

                <UtilTag
                  v-if="compCompanyType && !locked"
                  class="mr-1 leading-snug border bg-information-lighter border-information-darker"
                >
                  {{ compCompanyType }}
                </UtilTag>

                <span v-if="!appIs.auth && !userAlwaysUnlocked" class="truncate">
                  <UtilTextLink
                    v-if="!appIs.auth"
                    @click.native.stop.prevent="
                      $store.dispatch('modal/showModal', {
                        modalName: 'ModalAuthLogin',
                        modalData: { redirectToDashboard: false }
                      })
                    "
                    class="font-medium underline cursor-pointer text-brand"
                  >
                    Login for more info
                  </UtilTextLink>
                </span>
                <span v-else-if="locked" class="truncate">
                  <UtilButton
                    v-if="showLockedButton"
                    @click.native.stop.prevent="restrictedAction()"
                    color="primary"
                    class="mt-2 font-medium"
                  >
                    <FontAwesomeIcon :icon="faLockAlt" />
                    <span>Upgrade for full details</span>
                  </UtilButton>

                  <UtilTextLink
                    v-else
                    @click.native.stop.prevent="restrictedAction()"
                    class="font-medium underline cursor-pointer text-brand"
                  >
                    Upgrade to see owner details
                  </UtilTextLink>
                </span>
                <span v-else class="capitalize truncate">{{ compCompanyName }}</span>
              </p>

              <!-- <p v-if="showPendingVerification && pendingVerification" class="text-gray verified-animate">
                <small>{{ $t('feedback.userPendingVerification') }}...</small>
              </p> -->

              <slot name="meta">
                <p v-if="showStats" class="text-sm text-gray-darker">
                  <span v-if="statsApi.loading" class="flex">
                    <span class="invisible">Loading</span>
                  </span>

                  <template v-else-if="statsApi.data.user && statsApi.data.user.listingsCount">
                    <span>{{ $t('trust.numberListed', { count: statsApi.data.user.listingsCount }) }}</span>

                    <template v-if="statsApi.data.user.reviewsCount > 0">
                      <span>
                        <span>,</span>
                        <span>
                          {{ $t('trust.positiveFeedback', { percentage: `${compPercentagePositive}%` }) }}
                        </span>
                      </span>
                    </template>

                    <template v-else>
                      <span>, {{ $t('trust.memberSince', { date: compMemberSince }) }}</span>
                    </template>
                  </template>
                </p>
              </slot>
            </div>
          </slot>
        </template>

        <template #action>
          <slot name="action"></slot>
        </template>
      </UtilTileBase>
    </component>
  </div>
</template>

<script>
import LegacyApi from '@/lib/api/legacy'
import { faLockAlt, faThumbsDown, faThumbsUp } from '@fortawesome/pro-solid-svg-icons'
import getInitialsFromName from '@/lib/name-initials'
import UtilTileBase from '@/components/utils/UtilTileBase'
import UtilAvatar from '@/components/utils/UtilAvatar'
import UtilTag from '@/components/utils/UtilTag'
import UtilVerifiedBadge from '@/components/utils/UtilVerifiedBadge'
import UtilTextLink from '@/components/utils/UtilTextLink'
import UtilButton from '@/components/utils/UtilButton'
import UtilMembershipStatus from '@/components/utils/UtilMembershipStatus'
import UserModel from '@/lib/models/user'
import CompanyModel from '@/lib/models/company'

export default {
  name: 'WidgetUac',
  components: {
    UtilTileBase,
    UtilAvatar,
    UtilTag,
    UtilVerifiedBadge,
    UtilTextLink,
    UtilButton,
    UtilMembershipStatus
  },
  props: {
    user: {
      type: Object,
      default: () => new UserModel()
    },
    company: {
      type: Object,
      default: () => new CompanyModel()
    },
    forceName: String,
    forceCompany: String,
    forceAvatar: String,
    imageCacheBustParam: {
      type: Number,
      default: 1
    },
    showPendingVerification: Boolean,
    showVerifiedBadge: {
      type: Boolean,
      default: true
    },
    showMembershipStatus: {
      type: Boolean,
      default: false
    },
    showAvatar: {
      type: Boolean,
      default: true
    },
    showUser: {
      type: Boolean,
      default: true
    },
    showCompany: {
      type: Boolean,
      default: true
    },
    showFlag: {
      type: Boolean,
      default: true
    },
    showStats: Boolean,
    locked: {
      type: Boolean,
      default: false
    },
    showLockedButton: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: true
    },
    lazyLoad: {
      type: Boolean,
      default: true
    },
    vertical: Boolean,
    extraLarge: Boolean,
    autoWidth: Boolean,
    compact: Boolean,
    linkToProfile: Boolean,
    matchMaker: {
      type: Boolean,
      default: false
    },
    locationOrigin: {
      type: String,
      default: 'UAC'
    },
    userAlwaysUnlocked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statsApi: new LegacyApi(),
      pendingVerification: !this.user.verified,
      faThumbsUp,
      faThumbsDown,
      faLockAlt
    }
  },
  created() {
    if (this.showStats) {
      this.statsApi.post('users.stats', {
        userRef: this.user.userRef
      })
    }
  },
  computed: {
    compUserName() {
      if (this.forceName) {
        return this.forceName
      }

      if (this.matchMaker) {
        return this.user.firstName
      } else {
        return this.user.firstName || this.user.lastName
          ? `${this.user.firstName} ${this.user.lastName}`
          : 'John Smith'
      }
    },

    compInitials() {
      return getInitialsFromName(this.compUserName)
    },

    compCompanyName() {
      if (this.forceCompany) {
        return this.forceCompany
      }

      return this.company.name
    },

    compCompanyType() {
      if (!this.statsApi.data.company) {
        return false
      }

      switch (this.statsApi.data.company.types) {
        case 'Farm':
          return 'Farmer'

        case 'Procurement':
          return 'Agent'

        default:
          return this.statsApi.data.company.types
      }
    },

    compAvatarUrl() {
      if (this.forceAvatar) {
        return this.forceAvatar
      }

      const picFileRef = this.user.picFileRef || null

      if (picFileRef) {
        return `${this.assetBaseUrl.imageThumbnail}${picFileRef}?${this.imageCacheBustParam}`
      } else {
        return null
      }
    },

    compCompanyLogoUrl() {
      return `${this.assetBaseUrl.imageThumbnail}${this.company.picFileRef}?${this.imageCacheBustParam}`
    },

    compVerifiedToolTip() {
      return this.pendingVerification
        ? this.$t('verification.verifiedBadge')
        : this.$t('verification.userVerified')
    },

    compMemberSince() {
      return this.$date(this.statsApi.data.user.memberSince, this.$date.legacyFormatDateOnly).format('MMM YYYY')
    },

    compProfileUrl() {
      return `/profile/${this.user.firstName}-${this.user.lastName}/${this.user.userRef}`
    },

    compPercentagePositive() {
      let data = this.statsApi.data.user
      let score = (data.reviewsRating * 10).toFixed(0)
      return score
    },

    compMembershipStatus() {
      // This is to catch any legacy subs and make sure they show as Gold.
      if (this.user?.subscriptionPlan?.includes('premium') || this.user?.subscriptionPlan?.includes('prime')) {
        return 'gold'
      }

      switch (this.user.subscriptionLevel) {
        case 100:
          return 'bronze'

        case 200:
          return 'silver'

        case 300:
          return 'gold'

        case 400:
          return 'platinum'

        default:
          return 'bronze'
      }
    }
  },
  methods: {
    handleClick() {
      if (this.linkToProfile) {
        this.$store.dispatch('modal/closeModals')
      }

      this.$emit('click')
    },

    restrictedAction() {
      if (!this.appIs.auth) {
        this.login()
      } else {
        this.$store.dispatch('payments/showSubscriptionPlanChooser', {
          origin: this.locationOrigin
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.company-logo {
  height: 30px;
  align-self: flex-start;
}

.uac-body {
  display: flex;
  flex-direction: column;
}

.avatar .verified-icon {
  background: none;
  width: 37%;
}

.avatar .member-status {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.verified-icon::v-deep img {
  position: absolute;
  top: 0;
}
</style>
