import ApiCore from './core'

class Api extends ApiCore {
  constructor(model, timeout = 30000) {
    super(model, timeout)

    this.axios.interceptors.request.use(config => {
      const store = require('@/store').default

      config.baseURL = process.env.VUE_APP_API_BASE_URL
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${store.state.auth.token}`,
        'hctr-application': process.env.VUE_APP_HCTR_APPLICATION
      }

      // Derek's APIs require userRef to be in header instead of a parameter or body
      if (config.url.includes('/notify/')) {
        // Alex added Feb 2025 as dev slack API is 500ing
        config.baseURL = 'https://sellmylivestock.com/api-hctr'

        let app = store.getters['app/getApp']

        config.headers.platform = 'sellmylivestock'
        config.headers.userRef = app.user.userRef
      }

      config.data = JSON.stringify(config.data)

      return config
    })
  }
}

export default Api
