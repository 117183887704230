<template>
  <footer class="bg-brand-darker container-padding-x">
    <div class="py-24 mx-auto max-w-7xl">
      <div class="flex flex-row flex-wrap">
        <div class="w-full md:w-5/12 lg:w-4/12">
          <h3 class="text-3xl font-semibold text-white">Subscribe to get weekly news, articles and listings</h3>

          <UtilMessage v-if="newsletterApi.status === 'success'" type="success" class="mt-4">
            Thank for you for subscribing to our newsletter
          </UtilMessage>

          <Form
            v-else
            @submit="subscribeToNewsletter()"
            ref="form"
            v-slot="{ formErrors, formSubmissionAttempted }"
          >
            <FormErrorReport
              class="mt-4"
              :formSubmissionAttempted="formSubmissionAttempted"
              :formErrors="formErrors"
            />

            <UtilApiError :models="[newsletterApi]" />

            <div class="flex items-center mt-4 sm:max-w-md">
              <FormTextInput
                type="email"
                name="email"
                v-model="newsletterApi.model.email"
                placeholder="Enter your email"
                rules="required|email"
                :showValidationError="false"
                class="flex w-full mb-0 email-input-subscribe"
                targetClass="flex flex-grow-1 border-none"
              />

              <UtilButton
                :loading="newsletterApi.loading"
                type="submit"
                color="primary"
                class="flex font-medium rounded-l-none sm:mt-0"
                style="height: 43px"
              >
                Join Us
              </UtilButton>
            </div>
          </Form>
        </div>

        <div class="flex flex-wrap w-full mt-6 md:w-7/12 md:pl-40 md:mt-0">
          <div class="w-1/2 sm:w-3/12 md:w-1/2 lg:w-3/12">
            <h4 class="font-normal text-gray-200">Services</h4>

            <ul class="mt-1">
              <li>
                <UtilTextLink to="/search/livestock" class="no-underline sublink-text">
                  Buy &amp; sell livestock
                </UtilTextLink>
              </li>

              <li>
                <UtilTextLink to="/search/feed-bedding" class="no-underline sublink-text">
                  Feed &amp; bedding
                </UtilTextLink>
              </li>

              <li>
                <UtilTextLink to="/membership-plans" class="no-underline sublink-text">Membership</UtilTextLink>
              </li>
            </ul>
          </div>

          <div class="w-1/2 sm:w-3/12 md:w-1/2 lg:w-3/12">
            <h4 class="font-normal text-gray-200">Support</h4>

            <ul class="mt-1">
              <li>
                <UtilTextLink to="/help/how-it-works" class="no-underline sublink-text">
                  Help &amp; FAQ
                </UtilTextLink>
              </li>

              <li>
                <UtilTextLink to="/app-download" class="no-underline sublink-text">Download app</UtilTextLink>
              </li>

              <li>
                <UtilTextLink to="/sitemap" class="no-underline sublink-text">Sitemap</UtilTextLink>
              </li>
            </ul>
          </div>

          <div class="w-1/2 pt-6 sm:w-3/12 md:w-1/2 lg:w-3/12 sm:pt-0 md:pt-6 lg:pt-0">
            <h4 class="font-normal text-gray-200">Company</h4>

            <ul class="mt-1">
              <li>
                <UtilTextLink to="/blog" class="no-underline sublink-text">Blog &amp; podcasts</UtilTextLink>
              </li>

              <li>
                <UtilTextLink to="/about" class="no-underline sublink-text">About</UtilTextLink>
              </li>
            </ul>
          </div>

          <div class="w-1/2 pt-6 sm:w-3/12 md:w-1/2 lg:w-3/12 sm:pt-0 md:pt-6 lg:pt-0">
            <h4 class="font-normal text-gray-200">Legal</h4>

            <ul class="mt-1">
              <li>
                <UtilTextLink to="/terms" class="no-underline sublink-text">Terms &amp; privacy</UtilTextLink>
              </li>

              <li>
                <UtilTextLink to="/manage-cookies" class="no-underline sublink-text">Manage cookies</UtilTextLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="pb-12 mx-auto max-w-7xl">
      <div class="flex flex-col-reverse flex-wrap items-center justify-between sm:flex-row">
        <span class="w-full mt-2 text-sm text-white sm:w-auto sm:mt-0">
          &copy; SellMyLivestock {{ new Date().getFullYear() }}. All rights reserved.

          <span :class="appIs.isOutOfDate ? 'text-red-400' : 'text-white'">
            v{{ $store.state.device.version }}
          </span>

          <span class="block">
            Hectare Agritech Ltd, Wadebridge House, 16 Wadebridge Square, Poundbury, Dorchester, DT1 3AQ
          </span>
        </span>

        <div class="flex items-center w-full text-4xl sm:w-auto">
          <a
            href="https://www.facebook.com/sellmylivestock"
            target="_blank"
            rel="noopener noreferrer"
            class="text-gray-200 hover:text-gray-100"
          >
            <FontAwesomeIcon :icon="faFacebookF" />
          </a>

          <a
            href="https://twitter.com/sellmylivestock"
            target="_blank"
            rel="noopener noreferrer"
            class="ml-6 text-gray-200 hover:text-gray-100"
          >
            <FontAwesomeIcon :icon="faTwitter" />
          </a>

          <a
            href="https://www.instagram.com/sellmylivestock"
            target="_blank"
            rel="noopener noreferrer"
            class="ml-6 text-gray-200 hover:text-gray-100"
          >
            <FontAwesomeIcon :icon="faInstagram" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { faApple, faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import Api from '@/lib/api'
import UtilTextLink from '@/components/utils/UtilTextLink'
import UtilButton from '@/components/utils/UtilButton'
import FormTextInput from '@/components/forms/elements/FormTextInput'
import FormErrorReport from '@/components/forms/elements/FormErrorReport'
import Form from '@/components/forms/elements/Form'
import UtilApiError from '@/components/utils/UtilApiError'
import UtilMessage from '@/components/utils/UtilMessage'

export default {
  name: 'LayoutFooterNew',
  components: {
    UtilButton,
    UtilTextLink,
    FormTextInput,
    FormErrorReport,
    Form,
    UtilApiError,
    UtilMessage
  },
  data() {
    return {
      newsletterApi: new Api({
        email: ''
      }),
      faApple,
      faFacebookF,
      faTwitter,
      faInstagram
    }
  },
  methods: {
    subscribeToNewsletter() {
      this.newsletterApi.post('/notify/v1/slack', {
        message: `Newsletter signup - ${this.newsletterApi.model.email}`,
        channels: ['smlsocialmedia']
      })
    }
  }
}
</script>

<style lang="scss">
.app-button {
  height: 35px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

.email-input-subscribe {
  > div {
    @apply flex-grow;
    > div {
      @apply flex-grow;
      .form-input {
        @apply flex-grow;
      }
    }
  }
  .form-input {
    @apply rounded-r-none;
  }
}

.sublink-text.text-link {
  @apply text-gray-100 font-light text-sm leading-snug;
  &:hover {
    @apply text-gray-200;
  }
}
</style>
