import { render, staticRenderFns } from "./UtilMessage.vue?vue&type=template&id=70fd5900&scoped=true"
import script from "./UtilMessage.vue?vue&type=script&lang=js"
export * from "./UtilMessage.vue?vue&type=script&lang=js"
import style0 from "./UtilMessage.vue?vue&type=style&index=0&id=70fd5900&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70fd5900",
  null
  
)

export default component.exports