<template>
  <UtilMessage v-if="compErrors" type="error" large>
    <span>{{ $t('feedback.apiErrorProblem') }}.</span>

    <i18n v-if="!showOnlyMessage" path="feedback.tryAgainContact" tag="p">
      <h4 class="lowercase">{{ $t('feedback.contactUs') }}</h4>

      <div class="mt-2">
        <FontAwesomeIcon :icon="faEnvelope" />

        <a href="mailto:support@sellmylivestock.co.uk">support@sellmylivestock.co.uk</a>
      </div>
    </i18n>

    <h6 v-if="!showOnlyMessage" class="mt-2">{{ $t('feedback.errorDetails') }}:</h6>

    <div class="api-errors" :class="{ 'mt-2': !showOnlyMessage }" v-html="compErrors" />

    <UtilWarningOffline v-if="!appIs.online" />
  </UtilMessage>
</template>

<script>
import UtilWarningOffline from '@/components/utils/UtilWarningOffline'
import UtilMessage from '@/components/utils/UtilMessage'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'

// Don't bother putting an v-if when using this component, we'll handle
// the display condition here to reduce verbosity
export default {
  name: 'UtilApiError',
  components: {
    UtilMessage,
    UtilWarningOffline
  },
  props: {
    models: {
      type: Array,
      required: true
    },
    showOnlyMessage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      faEnvelope
    }
  },
  watch: {
    async compErrors(newValue) {
      if (newValue) {
        await this.$nextTick()
        this.$scroll.intoViewIfNeeded(document.querySelector('.api-errors'))
      }
    }
  },
  computed: {
    compErrors() {
      let errorMessages = []

      this.models.forEach(api => {
        if (api.status === 'error' && api.message) {
          errorMessages.push(api.message)
        }
      })

      // Remove duplicate error messages from array
      errorMessages = [...new Set(errorMessages)]

      return errorMessages.join('<br />')
    }
  }
}
</script>

<style lang="scss">
.api-errors ul {
  display: inline-block;
  margin-top: 0px;
  margin-left: 0px;
}
</style>
